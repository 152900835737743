import { InstanceManager } from '~commons/instance/get-instance';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BiLogger, BiLoggerDefaults } from './types';

export const buildBiLogger = (
  instanceManager: InstanceManager,
  flowAPI: ControllerFlowAPI,
): BiLogger => {
  const { platformAPIs } = flowAPI.controllerConfig;
  const maybeLoggerFactory = platformAPIs && platformAPIs.biLoggerFactory?.();

  // In iframe we get different object here, so lets catch some ducks
  const loggerFactory = maybeLoggerFactory?.logger
    ? // ooi
      maybeLoggerFactory
    : // iframe - factory is nested deeper
      (maybeLoggerFactory as any)?.factory?.();

  if (!loggerFactory || !loggerFactory.logger) {
    throw new Error('BI Logger factory not found or is unsupported');
  }

  const logger = loggerFactory.logger();
  const updateLoggerDefaults = () =>
    loggerFactory.updateDefaults(getLoggerDefaultValues(instanceManager));

  updateLoggerDefaults();

  instanceManager.onChange(updateLoggerDefaults);

  return logger;
};

const getLoggerDefaultValues = (instanceManager: InstanceManager): BiLoggerDefaults => {
  const { appDefId, uid, aid, metaSiteId } = instanceManager.getInstanceValues();

  return {
    msid: metaSiteId,
    app_id: appDefId,
    site_member_id: uid ?? undefined,
    visitor_id: aid ?? undefined,
    src: 69,
  };
};
